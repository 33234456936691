// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const APP_VERSION = '2.0.0';
export const API_BASE_URL = '/api';
export const ENABLE_REDUX_LOGGER = false;
export const BACKEND_URL = 'https://dev.bars.itmo.su/backend';
export const USER_CHECK_INTERVAL = '1';
export const REDIRECT_URI = 'https://dev.bars.itmo.su/rest/login';
export const SSO_URL = (redirectUri) => {
    localStorage.setItem('redirectUri', redirectUri);
    return 'https://id.itmo.ru/auth/realms/itmo/protocol/openid-connect/auth?response_type=code&scope=openid&client_id=bars-test&redirect_uri=https://dev.bars.itmo.su/rest/login';
}

// export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
// export const USER_CHECK_INTERVAL = process.env.REACT_APP_CHECK_USER_INTERVAL;
// export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
// export const SSO_URL = (redirectUri) => {
//     localStorage.setItem('redirectUri', redirectUri);
//     return process.env.REACT_APP_SSO_URL;
// }
export default {};